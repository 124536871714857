import { DashboardData } from './dashboard/Dashboard';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { get, webApi } from './utils/http';
import { useMemo } from 'react';
import { addMonths, format } from 'date-fns';

export enum ENDPOINT {
    OS_DASHBOARD = 'api/os/dashboard/',
}

export enum QUERY_KEY {
    OS_DASHBOARD = 'osDashboard',
}

interface DashboardQueryParams {
    defaultStartDate: string | null;
    defaultEndDate: string | null;
    startDate: string;
    endDate: string;
}

export const useOSDashboardQuery = (params: DashboardQueryParams) => {
    const queryKey = useMemo(() => [QUERY_KEY.OS_DASHBOARD, params], [params]);
    const startDate = params.startDate
        ? format(addMonths(new Date(params.startDate), 1), 'MMYYY')
        : params.defaultStartDate
        ? params.defaultStartDate
        : null;
    const endDate = params.endDate
        ? format(addMonths(new Date(params.endDate), 1), 'MMYYY')
        : params.defaultEndDate
        ? params.defaultEndDate
        : null;

    return useQuery<DashboardData, AxiosError>(queryKey, async () => {
        const response = await get(
            webApi + ENDPOINT.OS_DASHBOARD,
            false,
            params.startDate || params.endDate
                ? {
                      desde: startDate || '',
                      hasta: endDate || '',
                  }
                : undefined,
        );
        return response;
    });
};
